:root {
  --vip-primary-color: #131c2c; /* dark blue */
  --vip-secondary-color: #dce5f5;
  --vip-text-color: #002d73;
  --vip-gray-color: #717171;
  --vip-stroke-color: #e3e3ea;
  --vip-gold-color: linear-gradient(
    88deg,
    #927a5e -2.97%,
    #fff7ec 23.16%,
    #91786a 60.9%,
    #fff9e7 102.94%
  );
  --vip-silver-color: linear-gradient(
    87deg,
    rgba(255, 255, 255, 0.26) -4.15%,
    #f5f6f7 25.75%,
    rgba(255, 255, 255, 0.34) 68.91%,
    rgba(255, 255, 255, 0.81) 106.71%
  );
  --vip-card-name-color: linear-gradient(
    88deg,
    #fff -44.57%,
    rgba(255, 255, 255, 0) 171.54%
  );
  --font-aktiv: 'aktiv-grotesk-thai';
}

.db {
  border: 1px solid salmon !important;
}

body {
  background: white;
  padding: 0;
  margin: 0;
}

db {
  border: 1px solid salmon;
}

#vip {
  .vip-button-dark {
    @apply tw-bg-vip-primary tw-text-white tw-mt-8 tw-border-0 tw-text-lg tw-font-bold tw-px-12 tw-py-3 tw-rounded-full tw-cursor-pointer tw-text-center;
  }
  .vip-button-dark:disabled {
    @apply tw-bg-vip-stroke tw-text-white tw-mt-8 tw-border-0 tw-text-lg tw-font-bold tw-px-12 tw-py-3 tw-rounded-full tw-cursor-pointer tw-text-center;
  }
  .vip-button-light {
    color: var(--vip-primary-color);
    @apply tw-bg-white tw-text-vip-primary tw-border-0 tw-text-lg tw-font-bold tw-px-12 tw-py-3 tw-rounded-full tw-cursor-pointer tw-text-center;
  }
  .vip-button-secondary {
    color: var(--vip-primary-color);
    @apply tw-bg-vip-stroke tw-text-vip-text tw-mt-8 tw-border-0 tw-text-lg tw-font-bold tw-px-12 tw-py-3 tw-rounded-full tw-cursor-pointer tw-text-center;
  }
}

[lang='en'] #vip {
  font-family:
    var(--font-satoshi),
    var(--font-aktiv),
    var(--font-sarabun),
    BlinkMacSystemFont,
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    sans-serif;
}

[lang='th'] #vip {
  font-family:
    var(--font-aktiv),
    var(--font-satoshi),
    var(--font-sarabun),
    BlinkMacSystemFont,
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 1.5rem;
}

h2 {
  font-weight: 700;
  font-size: 1.25rem;
}

p {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
  color: var(--vip-gray-color);
}

@media (max-width: 500px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 1.375rem;
    line-height: 1.15;
  }

  h2 {
    font-size: 1rem;
    line-height: 1.3;
  }

  p {
    font-size: 13px;
    line-height: 1.5;
    color: var(--vip-gray-color);
  }
}

.vip-container {
  @apply tw-px-8 min-[1700px]:tw-px-40 min-[540px]:tw-px-20;
}
.vip-offset-header {
  @apply md:tw-pt-28 tw-pt-24;
}
.vip-offset-nav-desktop {
  @apply md:tw-pb-48 tw-pb-4;
}
.vip-offset-nav {
  @apply tw-pb-32 md:tw-pb-0;
}

.home .swiper-pagination {
  @apply tw-flex tw-mb-[30vh] md:tw-mb-[33vh] vip-container;
}
.home .swiper-pagination-bullet {
  @apply !tw-bg-white tw-duration-300 tw-opacity-30;
}

.home .swiper-slide > div {
  @apply tw-duration-1000;
}

.privilege .swiper-pagination {
  @apply tw-flex tw-mb-[7vh] sm:tw-mb-[10vh] vip-container;
}

.privilege .swiper-pagination-bullet {
  @apply !tw-bg-white tw-duration-300 tw-opacity-30;
}

.dashboard-privilege .swiper-button-prev {
  @apply tw--mt-16 tw-ml-10 min-[1700px]:tw-ml-32;
}

.dashboard-privilege .swiper-button-next {
  @apply tw--mt-16 tw--mr-2;
}

.dashboard-privilege.swiper-container {
  @apply vip-container;
}

.booking .swiper-button-prev {
  @apply tw-ml-10 min-[1700px]:tw-ml-32;
}

.booking .swiper-button-next {
  @apply tw--mr-2;
}

.booking.swiper-container {
  @apply vip-container;
}

.vip-text-detail {
  @apply tw-text-[13px] md:tw-text-[16px];
}
.image-filter {
  @apply tw-brightness-75;
}
.gradient-gold {
  background: linear-gradient(
    88deg,
    #927a5e -2.97%,
    #fff7ec 23.16%,
    #91786a 60.9%,
    #fff9e7 102.94%
  );
}

.gradient-silver {
  background: linear-gradient(
    87deg,
    rgba(255, 255, 255, 0.26) -4.15%,
    #f5f6f7 25.75%,
    rgba(255, 255, 255, 0.34) 68.91%,
    rgba(255, 255, 255, 0.81) 106.71%
  );
}

.text-gradient-gold {
  background-image: linear-gradient(
    88deg,
    #927a5e -2.97%,
    #fff7ec 23.16%,
    #91786a 60.9%,
    #fff9e7 102.94%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradient-silver {
  background-image: linear-gradient(
    87deg,
    rgba(255, 255, 255, 0.26) -4.15%,
    #f5f6f7 25.75%,
    rgba(255, 255, 255, 0.34) 68.91%,
    rgba(255, 255, 255, 0.81) 106.71%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.text-card-name {
  background-image: linear-gradient(
    88deg,
    #fff -44.57%,
    rgba(255, 255, 255, 0) 171.54%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
